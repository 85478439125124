@import (less) "app/views/login/login.less";
@import (less) "app/views/register/register.less";
@import (less) "app/views/admin/admin.less";
@import (less) "app/views/admin-dashboard/admin-dashboard.less";
@import (less) "app/views/admin-map/admin-map.less";
@import (less) "app/views/admin-report/admin-report.less";
@import (less) "app/views/admin-settings/admin-settings.less";
@import (less) "app/views/admin-table/admin-table.less";
@import (less) "app/views/field-home/field-home.less";
@import (less) "app/components/report-templates-list/report-templates-list.less";
@import (less) "app/views/template-activity/template-activity.less";
@import (less) "app/components/admin-table-list/admin-table-list.less";
@import (less) "app/views/admin-table/admin-table.less";
@import (less) "app/views/admin-map/admin-map.less";
@import (less) "app/components/field-home-today/field-home-today.less";
@import (less) "app/components/user-list/user-list.less";
@import (less) "app/components/customer-list/customer-list.less";

html, body, #root {
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.global-container{
  position: absolute;
  width: 100%;
  height: 100%;
}

.scroller{
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.horizontalAlign{
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.verticalAlign{
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.alignCenter{
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

md-toolbar{
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important;
}

.toolbar-title { 
  position: absolute;
}

.toolbar-icon {
  position: absolute;
  height: 68px;
  width: 72px;
  top: 10px;
  margin-left: 20px;
}

.input-error{
  line-height: 14px;
  font-size: 12px;
  color: rgb(221,44,0);
  margin-top: 35px;
}

.textarea-error{
  line-height: 14px;
  font-size: 12px;
  color: rgb(221,44,0);
  margin-top: 7px;
}

.select-error{
  line-height: 14px;
  font-size: 12px;
  color: rgb(221,44,0);
  margin-top: 5px;
}

.bold-roboto-14 {
  font-size: 14pt;
  font-weight: 500;
}

.padding-20-10 {
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

@media screen and (max-width: 599px) {
  .toolbar-title{
    right: 0px;
    margin-right: 10px;
    text-align: right;
    .verticalAlign;
    width: 235px;
  }
}

@media screen and (min-width: 600px) {
  .toolbar-title{
    right: 0px;
    margin-right: 10px;
    text-align: right;
    .verticalAlign;
    width: 235px;
  }
}

@media screen and (min-width: 640px) {

}

@media screen and (min-width: 960px) {
  .toolbar-title{
    .alignCenter;
    width: 300px;
    text-align: center;
    float: none;
  }
}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1920px) {
  
}