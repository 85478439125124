.settings-tabs-padding {
  padding-top: 13px;
}

.settings-tabs-padding md-tab-content > div {
  height: 100%;
}

md-tab-content {
  overflow: hidden;
}

.settings-tab {
  width: 100%;
  height: 100%;
}

.settings-add-container { 
  position: relative;
  float: left;
  bottom: 0;
  width: 100%;
  text-align: right;
  box-sizing: border-box;
}

.sett-usr-crecode {
  position: relative;
  width: 100%;
  word-wrap: break-word;
}


@media screen and (max-width: 599px) {

}

@media screen and (min-width: 600px) {

}

@media screen and (min-width: 640px) {

}

@media screen and (min-width: 960px) {

}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1920px) {
  
}