.rep-temp-item {
  margin: 0;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 5px;
  cursor: pointer;
}

@media screen and (max-width: 599px) {

}

@media screen and (min-width: 600px) {

}

@media screen and (min-width: 640px) {

}

@media screen and (min-width: 960px) {
  
}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1920px) {
  
}