ng-map {
  height: 100% !important;
}

.admin-map-filter {
  position: absolute;
  top: 58px;
  right: 15px;
}

.admin-map-list {
  position: absolute;
  top: 105px;
  right: 15px;
}

@media screen and (max-width: 599px) {

}

@media screen and (min-width: 600px) {

}

@media screen and (min-width: 640px) {

}

@media screen and (min-width: 960px) {
  
}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1920px) {
  
}