.register-form md-input-container{
  margin-top: 10px;
  margin-bottom: 10px;
  height: 58px !important;
}

@media screen and (max-width: 599px) {
  .register-form{
    height: 700px;
  }
}

@media screen and (min-width: 600px) {
  .register-form{
    height: 700px;
  }
}

@media screen and (min-width: 640px) {

}

@media screen and (min-width: 960px) {
  
}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1920px) {
  
}