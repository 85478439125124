.customer-list-container {
  height: 60px;
  box-sizing: border-box;
}

@media screen and (max-width: 599px) {

}

@media screen and (min-width: 600px) {

}


@media screen and (min-width: 640px) {

}

@media screen and (min-width: 960px) {

}

@media screen and (min-width: 1280px) {
  
}

@media screen and (min-width: 1920px) {
  
}