.field-home-item {
  border-bottom: 1px solid grey;
  cursor: pointer;
  height: 40px;
  box-sizing: border-box;
}

@media screen and (max-width: 599px) {

}

@media screen and (min-width: 600px) {

}

@media screen and (min-width: 640px) {

}

@media screen and (min-width: 960px) {
  .field-home-item:hover {
    background-color: #2196F3;
    color: white;
  }
}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1920px) {
  
}