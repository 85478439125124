.admin-tbl-list {
  height: 45px;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid grey;
  line-height: 45px;
  font-weight: 300;
}

.admin-tbl-list-first {
  border-top: 1px solid grey;
}

.admin-tbl-header {
  font-weight: 500 !important;
}


.admin-tbl-element{
  border-right: 1px solid grey;
  box-sizing: border-box;
  text-align: center;
}

.admin-tbl-view-report-title {
  color: #1E88E5;
}

.admin-tbl-view-report-file {
  color: black;
}

@media screen and (max-width: 599px) {
  .admin-tbl-list {
    font-size: 8pt;
  }
}

@media screen and (min-width: 600px) {
  .admin-tbl-list {
    font-size: 8pt;
  }
}

@media screen and (min-width: 640px) {
  .admin-tbl-list {
    font-size: 9pt;
  }
}

@media screen and (min-width: 960px) {
  .admin-tbl-list {
    font-size: 10pt;
  }

  .admin-tbl-list:hover {
    background-color: #1E88E5;
    color: white;
  }
}

@media screen and (min-width: 1280px) {
  
}

@media screen and (min-width: 1920px) {
  
}