.login-form {
  height: 100%;
}
.login-box {
  background-color: white;
  height: 100%;
  width: 100%;
}
.login-logo {
  height: 85px;
  width: auto;
}
.login-new-user {
  position: relative;
  color: #2196F3;
  text-decoration: none;
  font-size: 14pt;
  cursor: pointer;
}
@media screen and (max-width: 599px) {
  .login-box-wrapper {
    height: 100%;
    width: 100%;
  }
  .login-logo {
    height: 65px;
    width: auto;
  }
}
@media screen and (min-width: 600px) {
  .login-box-wrapper {
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 640px) {
  .login-box-wrapper {
    height: 500px;
    width: 640px;
  }
}
.register-form md-input-container {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 58px !important;
}
@media screen and (max-width: 599px) {
  .register-form {
    height: 700px;
  }
}
@media screen and (min-width: 600px) {
  .register-form {
    height: 700px;
  }
}
ng-map {
  height: 100% !important;
}
.admin-map-filter {
  position: absolute;
  top: 58px;
  right: 15px;
}
.admin-map-list {
  position: absolute;
  top: 105px;
  right: 15px;
}
.settings-tabs-padding {
  padding-top: 13px;
}
.settings-tabs-padding md-tab-content > div {
  height: 100%;
}
md-tab-content {
  overflow: hidden;
}
.settings-tab {
  width: 100%;
  height: 100%;
}
.settings-add-container {
  position: relative;
  float: left;
  bottom: 0;
  width: 100%;
  text-align: right;
  box-sizing: border-box;
}
.sett-usr-crecode {
  position: relative;
  width: 100%;
  word-wrap: break-word;
}
.admin-tbl-pages {
  box-sizing: border-box;
  height: 30px;
  line-height: 30px;
}
.field-home-item {
  border-bottom: 1px solid grey;
  cursor: pointer;
  height: 40px;
  box-sizing: border-box;
}
@media screen and (min-width: 960px) {
  .field-home-item:hover {
    background-color: #2196F3;
    color: white;
  }
}
.rep-temp-item {
  margin: 0;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 5px;
  cursor: pointer;
}
.visit-report md-input-container {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 58px !important;
}
.visit-report-files {
  padding-top: 50px !important;
  padding-bottom: 15px !important;
}
.visit-report-files md-input-container {
  margin-bottom: 0px;
  height: 29px !important;
}
.visit-report-files-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
@media screen and (max-width: 599px) {
  .visit-report {
    height: 700px;
  }
}
@media screen and (min-width: 600px) {
  .visit-report {
    height: 700px;
  }
}
.admin-tbl-list {
  height: 45px;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid grey;
  line-height: 45px;
  font-weight: 300;
}
.admin-tbl-list-first {
  border-top: 1px solid grey;
}
.admin-tbl-header {
  font-weight: 500 !important;
}
.admin-tbl-element {
  border-right: 1px solid grey;
  box-sizing: border-box;
  text-align: center;
}
.admin-tbl-view-report-title {
  color: #1E88E5;
}
.admin-tbl-view-report-file {
  color: black;
}
@media screen and (max-width: 599px) {
  .admin-tbl-list {
    font-size: 8pt;
  }
}
@media screen and (min-width: 600px) {
  .admin-tbl-list {
    font-size: 8pt;
  }
}
@media screen and (min-width: 640px) {
  .admin-tbl-list {
    font-size: 9pt;
  }
}
@media screen and (min-width: 960px) {
  .admin-tbl-list {
    font-size: 10pt;
  }
  .admin-tbl-list:hover {
    background-color: #1E88E5;
    color: white;
  }
}
.field-home-today {
  height: 45px;
  box-sizing: border-box;
  border-bottom: 1px solid grey;
  line-height: 45px;
  font-weight: 300;
}
.field-home-today-first {
  border-top: 1px solid grey;
}
.field-home-header {
  font-weight: 500 !important;
}
.field-home-element {
  border-right: 1px solid grey;
  box-sizing: border-box;
  text-align: center;
}
.field-home-view-report-title {
  color: #1E88E5;
}
.field-home-view-report-file {
  color: black;
}
@media screen and (max-width: 599px) {
  .field-home-today {
    font-size: 8pt;
  }
}
@media screen and (min-width: 600px) {
  .field-home-today {
    font-size: 8pt;
  }
}
@media screen and (min-width: 640px) {
  .field-home-today {
    font-size: 9pt;
  }
}
@media screen and (min-width: 960px) {
  .field-home-today {
    font-size: 10pt;
  }
  .field-home-today:hover {
    background-color: #1E88E5;
    color: white;
  }
}
.user-list-container {
  height: 60px;
  box-sizing: border-box;
}
.customer-list-container {
  height: 60px;
  box-sizing: border-box;
}
html,
body,
#root {
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
.global-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.scroller {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.horizontalAlign {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.verticalAlign {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.alignCenter {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
md-toolbar {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important;
}
.toolbar-title {
  position: absolute;
}
.toolbar-icon {
  position: absolute;
  height: 68px;
  width: 72px;
  top: 10px;
  margin-left: 20px;
}
.input-error {
  line-height: 14px;
  font-size: 12px;
  color: #dd2c00;
  margin-top: 35px;
}
.textarea-error {
  line-height: 14px;
  font-size: 12px;
  color: #dd2c00;
  margin-top: 7px;
}
.select-error {
  line-height: 14px;
  font-size: 12px;
  color: #dd2c00;
  margin-top: 5px;
}
.bold-roboto-14 {
  font-size: 14pt;
  font-weight: 500;
}
.padding-20-10 {
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.padding-bottom-15 {
  padding-bottom: 15px;
}
@media screen and (max-width: 599px) {
  .toolbar-title {
    right: 0px;
    margin-right: 10px;
    text-align: right;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 235px;
  }
}
@media screen and (min-width: 600px) {
  .toolbar-title {
    right: 0px;
    margin-right: 10px;
    text-align: right;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 235px;
  }
}
@media screen and (min-width: 960px) {
  .toolbar-title {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 300px;
    text-align: center;
    float: none;
  }
}
