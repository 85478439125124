.login-form{
  height: 100%;
}

.login-box{
  background-color: white;
  height: 100%;
  width: 100%;
}

.login-logo{
  height: 85px;
  width: auto;
}

.login-new-user{
  position: relative;
  color: #2196F3;
  text-decoration: none;
  font-size: 14pt;
  cursor: pointer;
}

@media screen and (max-width: 599px) {
  .login-box-wrapper{
    height: 100%;
    width: 100%;
  }

  .login-logo{
    height: 65px;
    width: auto;
  }
}

@media screen and (min-width: 600px) {
  .login-box-wrapper{
    height: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .login-box-wrapper{
    height: 500px;
    width: 640px;
  }
}

@media screen and (min-width: 960px) {
  
}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1920px) {
  
}