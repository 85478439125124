.field-home-today {
  height: 45px;
  box-sizing: border-box;
  border-bottom: 1px solid grey;
  line-height: 45px;
  font-weight: 300;
}

.field-home-today-first {
  border-top: 1px solid grey;
}

.field-home-header {
  font-weight: 500 !important;
}


.field-home-element{
  border-right: 1px solid grey;
  box-sizing: border-box;
  text-align: center;
}

.field-home-view-report-title {
  color: #1E88E5;
}

.field-home-view-report-file {
  color: black;
}

@media screen and (max-width: 599px) {
  .field-home-today {
    font-size: 8pt;
  }
}

@media screen and (min-width: 600px) {
  .field-home-today {
    font-size: 8pt;
  }
}

@media screen and (min-width: 640px) {
  .field-home-today {
    font-size: 9pt;
  }
}

@media screen and (min-width: 960px) {
  .field-home-today {
    font-size: 10pt;
  }

  .field-home-today:hover {
    background-color: #1E88E5;
    color: white;
  }
}

@media screen and (min-width: 1280px) {
  
}

@media screen and (min-width: 1920px) {
  
}