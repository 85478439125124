.visit-report md-input-container{
  margin-top: 10px;
  margin-bottom: 10px;
  height: 58px !important;
}

.visit-report-files {
  padding-top: 50px !important;
  padding-bottom: 15px !important;
}

.visit-report-files md-input-container{
  margin-bottom: 0px;
  height: 29px !important;
}

.visit-report-files-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 599px) {
  .visit-report{
    height: 700px;
  }
}

@media screen and (min-width: 600px) {
  .visit-report{
    height: 700px;
  }
}

@media screen and (min-width: 640px) {

}

@media screen and (min-width: 960px) {
  
}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1920px) {
  
}